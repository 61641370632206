import { V2SdkConfig } from './config'
import JSBI from 'jsbi'

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const FIVE = JSBI.BigInt(5)
export const _997 = JSBI.BigInt(997)
export const _1000 = JSBI.BigInt(1000)

const v2SdkConfigSingle = new V2SdkConfig()

export const v2SdkConfig = v2SdkConfigSingle
